import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

export default function AboutUs() {
  return (
    <>
      <section id="nosotros" className="relative flex items-center justify-center overflow-hidden wow animate__animated animate__fadeIn">
        <div className="relative px-5 py-10 md:py-20">
          <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">

            <div className="grid grid-cols-1 gap-x-6 lg:grid-cols-2">

              <div className="relative overflow-hidden wow animate__animated animate__fadeInLeft">
                <Image width={500} height={500} alt='Logo de Actionis con icono de usuarios' src="/icons/about-us-actonis.svg" className="object-contain w-16 md:w-24 md:mb-10 wow " />
                <p className="mt-2 uppercase md:mt-0 text-shadow-yellow text-4xl md:text-5xl lg:text-6xl font-extrabold wow">
                  ACERCA
                  DE NOSOTROS
                </p>
                <h2 className="sr-only">Acerca de Nosotros</h2>
                <p className="mt-3 mb-10 max-w-prose md:text-lg font-bold text-blue-light wow">
                  Experiencia y compromiso
                </p>
                <div >
                  <p className="mb-3 text-sm md:text-base wow">
                    Nos conocimos cuando éramos una niñas pequeñas y han trascurrido más de 40 años de amistad,
                    durante los cuales hemos crecimos, solidificado nuestra amistad y nos hemos desempeñado en
                    diversos ámbitos profesionales. El destino nos hizo vivir en los Estados Unidos, en la misma
                    ciudad: Miami; además, nos hizo vivir la experiencia de ser inmigrantes, de trabajar para
                    otras empresas y finalmente de tomar la iniciativa de convertirnos en emprendedoras…. Juntas!
                  </p>
                  <p className="mb-3 text-sm md:text-base wow">
                    Decimos complementar nuestras misiones, fortalezas y experiencias para fundar Actionis
                    Consulting Corp., en el año 2016.
                  </p>
                  <p className="mb-3 text-sm md:text-base wow">
                    La principal motivación para dar este paso fue que ambas compartimos la misma misión:
                    empoderar a personas, pequeñas y medianas empresas, a través de un servicio profesional, basado en la
                    comunicación y relación con nuestros clientes en el campo de la administración,
                    contabilidad, impuestos e identidad corporativa.
                  </p>
                  <p className="mb-3 text-sm md:text-base wow">
                    Después de experimentar y aprender los obstáculos que nos tocó vivir, especialmente como
                    inmigrantes en USA, vimos con claridad la necesidad que existe, no sólo para los que recién
                    llegan a este país, sino para todo aquel que quiere arrancar un negocio nuevo o mejorar el
                    que ya tiene.
                  </p>
                  <p className="mb-3 text-sm md:text-base wow">
                    La experiencia ha sido extraordinaria, en especial porque hemos sentido una gran
                    receptividad de parte de nuestros clientes, con quienes desarrollamos, además de una relación profesional,
                    un sentido de equipo.
                  </p>
                  <p className="mb-3 text-sm md:text-base wow">
                    La inspiración para desarrollar nuestra imagen gráfica se originó en la idea de que la base
                    de un buen servicio es la <span className="font-semibold">comunicación</span>, por eso usamos el
                    icono conocido como “Burbuja de diálogo”, cuya evolución dio como resultado nuestro logo.
                  </p>

                </div>
              </div>
              <div className=' relative flex h-[400px] sm:h-[700px] lg:h-full my-5 lg:my-auto'>
                <div data-wow-delay="1s" className='wow animate__animated animate__fadeIn absolute w-[55%] translate-y-[-20px]  h-[80%] '>
                  <div className="relative flex items-end w-full h-full overflow-visible ">
                    <Image fill sizes="(max-width: 768px) 35vw, (max-width: 1200px) 50vw, 33vw" alt="Maria y Melanie" data-wow-delay="0.5s" src="/images/about-melanie.jpg" className="object-cover rounded-xl animate__animated animate__fadeIn wow" />
                  </div>
                </div>

                <div data-wow-delay="2s" className='absolute    w-[55%]  translate-y-[20px] h-[80%] right-0 bottom-0'>
                  <div className="relative flex items-end w-full h-full overflow-visible ">
                    <Image fill sizes="(max-width: 768px) 35vw, (max-width: 1200px) 50vw, 33vw" alt="Maria y Melanie" data-wow-delay="0.5s" src="/images/about-magabriela.jpg" className="object-cover rounded-xl animate__animated animate__fadeIn wow" />
                  </div>
                </div>
              </div>
              <div className='col-span-1 mt-5'>
                <div className="flex flex-row items-center justify-between w-full gap-5 ">
                  <Image width={600} height={600} alt='Evolución del logo de Actionis' src="/images/evolution.png" className="hidden object-contain w-1/2 mb-10 md:block md:w-1/2 wow " />
                </div>
                <Link className="relative z-20 inline-block text-sm font-bold text-white group focus:outline-none focus:ring wow" href="#servicios">
                  <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 rounded-md bg-yellow-dark group-hover:translate-y-2 group-hover:translate-x-2" />
                  <span className="relative flex items-center px-8 py-3 rounded-md opensans-bold bg-blue-light gap-x-2">
                    Ver Servicios
                    <Image width={100} height={100} alt='Flecha' src="/icons/arrow.svg" className="object-contain w-6 h-6" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
