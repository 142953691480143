import HeadMeta from "@/components/common/HeadMeta";
import AboutUs from "@/components/sections/AboutUs";
import Contact from "@/components/sections/Contact";
import Hero from "@/components/sections/Hero";
import MarqueeComponent from "@/components/sections/MarqueeComponent";
import Methodology from "@/components/sections/Methodology";
import NewsletterCta from "@/components/sections/NewsletterCta";
import OurClients from "@/components/sections/OurClients";
import OurServices from "@/components/sections/OurServices";
import OurTeam from "@/components/sections/OurTeam";
import Posts from "@/components/sections/Posts";
import SpecializedService from "@/components/sections/SpecializedService";
import StarProducts from "@/components/sections/StarProducts";
import Testimonials from "@/components/sections/Testimonials";
import WeWorkWith from "@/components/sections/WeWorkWith";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export async function getStaticProps() {
  let slidesData = await getProps('/api/v1/hero');
  let servicesData = await getProps('/api/v1/services');
  let postsData = await getProps('/api/v1/posts');
  let testimonialsData = await getProps('/api/v1/testimonials');
  let logosData = await getProps('/api/v1/logos');

  return {
    props: {
      slides: slidesData.items,
      slidesError: slidesData.itemsError,

      services: servicesData.items,
      servicesError: servicesData.itemsError,

      posts: postsData.items.data,
      postsError: postsData.itemsError,

      testimonials: testimonialsData.items,
      testimonialsError: testimonialsData.itemsError,

      logos: logosData.items,
      logosError: logosData.itemsError,
    },
    revalidate: 10,
  };
}

let getProps = async (url) => {
  try {
    const resItems = await fetch(
      `${process.env.NEXT_PUBLIC_API}${url}`
    );
    const items = await resItems.json();
    return {
      items,
      itemsError: false,
    };
  } catch (error) {
    return {
      items: [],
      itemsError: true,
    };
  }
};



export default function Home({ slides, services, posts, testimonials, logos }) {
  const [subject, setSubject] = useState('')
  const router = useRouter();
  useEffect(() => {
    if (router.asPath.includes('#')) {
      const hash = router.asPath.split('#')[1];
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
    }
  }, [router.asPath]);
  return (
    <>
      <h1 className="sr-only">Consultoría contable y fiscal personalizada para potenciar tu negocio</h1>

      <HeadMeta title="Consultoría Contable, Fiscal y Empresarial Personalizada para Empresas y Personas" />
      <Hero data={slides} />
      <SpecializedService />
      <OurServices data={services} state={setSubject} />
      <AboutUs />
      <WeWorkWith />
      <OurTeam />
      <MarqueeComponent />
      <NewsletterCta />
      <Methodology />
      <StarProducts data={services} />
      <Posts data={posts} />
      <Contact subjectFromState={subject} />
      <Testimonials data={testimonials} />
      <OurClients data={logos} />
    </>
  )
}

Home.propTypes = {
  slides: PropTypes.any,
  services: PropTypes.any,
  posts: PropTypes.any,
  testimonials: PropTypes.any,
  logos: PropTypes.any,
};