  import Image from "next/image";
  import React from "react";

  export default function SpecializedService() {
    return (
      <>
        <section
          id="specializedService"
          className="relative flex items-center justify-center overflow-hidden"
        >
          <div className="relative px-5 py-10 md:py-20">
            <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div data-wow-duration='0.8s' className="wow animate__animated animate__fadeInLeft">
                  <Image
                    width={500}
                    height={500}
                    src="/icons/hand-actonis.svg"
                    className="object-contain w-16 md:w-24 md:mb-10 "
                    alt="Logo con icono de mano"
                  />

                  <p className="mt-2 uppercase md:mt-0 text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">
                    SERVICIO PERSONALIZADO, <br /> CONSTRUYENDO RELACIONES
                  </p>
                  <h2 className="sr-only">
                    Servicio personalizado, construyendo relaciones
                  </h2>

                  <p className="mt-5 mb-10 text-lg max-w-prose text-blue-light font-bold">
                    Dirigido a pequeñas y medianas empresas y personas naturales.
                  </p>
                  <p className="mb-10 text-sm max-w-prose md:text-base ">
                    Nuestra <span className="font-bold">MISIÓN</span> es
                    ofrecer un servicio integral de consultoría para personas
                    jurídicas y naturales, cubriendo todas tus necesidades
                    contables, impositivas, administrativas, empresariales y de
                    marketing.{" "}
                    <span className="font-bold">
                      Nuestro norte es estar accesible para para ti.
                    </span>
                  </p>
                  <p className="mb-10 text-sm max-w-prose md:text-base ">
                    Nuestra <span className="font-bold">VISIÓN</span> está
                    orientada a ofrecer un servicio de consultoría basado en la
                    comunicación eficiente con nuestros clientes, a través de la
                    identificación de metas para poder determinar el camino y las
                    estrategias para apoyar tu emprendimiento, impulsar tu
                    desarrollo y consolidar tus sueños y metas personales.
                  </p>
                  <a
                    className="relative inline-block text-sm font-bold text-white group focus:outline-none focus:ring"
                    href="#servicios"
                  >
                    <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 rounded-md bg-yellow-dark group-hover:translate-y-2 group-hover:translate-x-2" />
                    <span className="relative flex items-center px-8 py-3 rounded-md bg-blue-light gap-x-2">
                      Ver Servicios
                      <Image
                        width={24}
                        height={24}
                        src="/icons/arrow.svg"
                        className="object-contain w-6 h-6"
                        alt="flecha"
                      />
                    </span>
                  </a>
                </div>

                <div data-wow-duration='0.8s' className="relative w-full wow animate__animated animate__fadeInRight rounded-xl">
                  <span
                    data-wow-duration="2s"
                    data-wow-delay="1s"
                    className="wow animate__animated animate__fadeIn  w-32 h-32 rounded-full absolute bottom-0 hidden md:block -z-10 translate-y-[60%]  border-4 border-gray-lighter"
                  />
                  <Image
                    width={1200}
                    height={1200}
                    sizes="(max-width: 768px) 70vw, (max-width: 1200px) 50vw, 33vw"
                    src="/images/specialized-service.jpg"
                    className="wow animate__animated  animate__fadeIn max-h-[500px]   md:max-h-none object-cover   rounded-xl md:rounded-bl-xl md:rounded-tl-xl  "
                    alt="Hora de impuestos"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
