import Image from "next/image";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ServicesModal from "./ServicesModal";
export default function OurServices({ data, state }) {
  const [service, setService] = useState(null);
  if (data && data.length) {
    return (
      <>
        {service && (
          <ServicesModal subject={state} state={setService} item={service} />
        )}
        <section id="servicios" className="relative px-5 py-10 md:py-20 ">
          <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
            <div
              data-wow-duration="1s"
              data-wow-delay={"0.1s"}
              className="grid grid-cols-2 gap-5 lg:grid-cols-3 animate__animated animate__fadeInUp wow"
              style={{ gridAutoRows: "1fr" }}
            >
              <div>
                <Image
                  width={200}
                  height={200}
                  src="/icons/nut-actonis.svg"
                  className="object-contain w-16 md:w-24 md:mb-10 "
                  alt="Logo de Actinos con icono de tuerca"
                />
                <p className="mt-2 uppercase md:mt-0 text-shadow-yellow text-4xl md:text-5xl lg:text-6xl font-extrabold">
                  NUESTROS SERVICIOS
                </p>
                <h2 className="sr-only">Nuestros Servicios</h2>
                <p className="mt-3 mb-10 text-sm max-w-prose md:text-lg font-bold text-blue-light ">
                  Consultoría de negocios
                </p>
              </div>
              {data.map((item, index) => (
                <div
                  key={index}
                  data-wow-duration="1s"
                  data-wow-delay={(index + 2) * 0.1 + "s"}
                  className="relative animate__animated animate__fadeInUp wow w-full h-full min-h-full p-2 transition-all duration-200 ease-linear bg-bottom bg-no-repeat bg-cover cursor-pointer sm:p-5 group hover:shadow-lg rounded-xl {'{'}{'{'} $item-&gt;slug . '-open' {'}'}{'}'}"
                  onClick={() => setService(item)}
                >
                  <Image fill sizes="(max-width: 768px) 10vw, (max-width: 1200px) 50vw, 33vw" src={item.image} alt={item.name} className="object-cover rounded-xl" />
                  <div className="relative z-10 flex flex-col items-center justify-center h-full ">
                    <Image
                      width={100}
                      height={100}
                      src={item.icon}
                      className="w-10 h-10 mb-3 sm:w-12 sm:h-12 "
                      alt={item.name}
                    />
                    <h3 className="mb-3 text-center uppercase text-yellow-dark sm:text-base md:text-lg font-bold">
                      {item.name}
                    </h3>
                    <p className="mb-5 text-xs md:text-base text-center text-white">
                      {item.intro}
                    </p>
                    <Image
                      width={50}
                      height={50}
                      src="/icons/arrow-3.svg"
                      className="w-6 h-6 sm:w-12 sm:h-12 "
                      alt="Flecha"
                    />
                  </div>
                  <span className="absolute top-0 left-0 w-full h-full transition-all duration-200 ease-linear bg-blue-light/90 group-hover:bg-gray-900/40 rounded-xl" />
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

OurServices.propTypes = {
  data: PropTypes.any,
  state: PropTypes.any,
};
