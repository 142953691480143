import { React } from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import Script from "next/script";
import { Favicon } from "./Favicon";

const HeadMeta = ({ title, description, keywords }) => {
  return (
    <>
      <Head>
        <title>{` ${title}`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content={description}
        />
        <meta
          name="keywords"
          content={keywords}
        />
        <Favicon />
      </Head>
      <Script src="/js/wow.min.js" strategy="afterInteractive"></Script>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=G-SL3VVTTZVB`}
      />
      <Script id="g-tag"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-SL3VVTTZVB');
              `,
        }}
      />
    </>
  );
};

HeadMeta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
};

HeadMeta.defaultProps = {
  title: "Pending Title",
  description: "Ofrecemos servicios de consultoría personalizados para pequeñas y medianas empresas y personas naturales. Amplia gama de servicios en administración, contabilidad, impuestos y marketing.",
  keywords: "Actionis, Consulting, Consultoría, Administración, Contabilidad, Impuestos, Marketing, Emprendimiento, Identidad Corporativa",
};

export default HeadMeta;
